import React from 'react'
import axios from 'axios'
import {
CardHeader,
CardBody,
CardText,
Card,Container,Col,Row,Button,Table} from 'reactstrap'
import cogoToast from 'cogo-toast'
import cookie from 'react-cookies'
class register extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               txs: []

          }
     }
     onChange = (e) => {
          this.setState({
               [e.target.name]: e.target.value
          })
     }

     componentDidMount(){

       const uid = cookie.load('uid')
       const formdata = new FormData()
       formdata.append('uid',uid)
       axios.post('/api/Wallet/Get_user_wallet',formdata).then(response=>{
         this.setState({txs : response.data})
       })

     }
     render() {

          return (
  <Container dir="rtl" style={{'margin-top':'40px'}}>
  <Card>
  <CardHeader className='text-success' tag="h3">دارائی ها</CardHeader>
  <CardBody>

  <Row xl="1" md='1' sm='1' xs='1'>
  <Col xl='12'>

  <Table responsive>
      <thead>
        <tr>

          <th>کیف پول</th>
          <th>موجودی</th>
          <th>ارزش فعلی</th>
          <th>واریز</th>
          <th>برداشت</th>
          <th>تراکنش ها</th>
        </tr>
      </thead>
      <tbody>
      {this.state.txs.map(tx =>(
        <>

       <tr>
       <td>
        {tx.symbol}
       </td>
       <td>
        {tx.balance}<i class="ion-ios-heart-outline"></i>
       </td>
       <td>
        {tx.value}<i class="fas fa-bacterium"></i>
       </td>
      <td>
      <Button onClick={this.location} outline size='sm'>واریز </Button>
      </td>
      <td>
      <Button outline size='sm'>برداشت </Button>
      </td>
      <td>
      <Button outline size='sm'>تراکنش ها</Button>
      </td>

       </tr>

        </>

      ))}

      </tbody>
    </Table>
  </Col>

  </Row>
  </CardBody>


  </Card>
  </Container>

     )

}}
export default register
