import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import Pay from '../css/logo.svg'

import {Link} from 'react-router-dom'
const Example = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className='py-2'>
      <Navbar color="dark" dark expand="md">
        <NavbarBrand className='mr-auto'><Link to='/'><img src={Pay} width='50px'  className='mt-2' /><span className='mx-2 my-auto'>RAPITEX</span></Link></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto rtl p-auto" navbar>
            <NavItem>
              <Link to="/Auth">ورود</Link>
            </NavItem>
            <NavItem className='' style={{'width' : '60px'}}>
              <Link to="/SignUp">ثبت نام</Link>
            </NavItem>
            <NavItem>
              <Link to="/Roules">قوانین</Link>
            </NavItem>
            <NavItem>
              <Link to="/contact-us">تماس با ما</Link>
            </NavItem>
            <NavItem>
              <Link to="/about-us">درباره ما</Link>
            </NavItem>
            <NavItem>
              <Link to="/complaining">ثبت شکایات</Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Example;
