import React from 'react'
import axios from 'axios'
import{Row,Col,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Link} from 'react-router-dom'
import Pay from '../css/logo.svg'
import ReCAPTCHA from 'react-google-recaptcha'
import cogoToast from 'cogo-toast'
import SimpleReactValidator from 'simple-react-validator'
class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }
this.validator = new SimpleReactValidator();
  }

         onSubmit = (e) => {
             e.preventDefault();

                   if (!this.validator.fieldValid('name')) {
                        cogoToast.error('نام خود را بصورت کامل وارد کنید')
                        return
                      }else{

                      }
                   if (!this.validator.fieldValid('email')) {
                        cogoToast.error('ایمیل معتبری وارد کنید')
                        return
                      }else{

                      }
                      if (!this.validator.fieldValid('password')) {
                           cogoToast.error('رمز عبور بین ۴ تا ۸ کاراکتر باید باشد')
                           return
                         }else{

                         }

                            if(this.state.captcha === ''){
                              cogoToast.error('خطا در تشخیص کاربر')
                              return
                            }else{

                            }
                              this.setState({active2 : false,bs : false})

             const { name,email, password } = this.state;
             const formdata = new FormData()
             formdata.append('email',this.state.email)
             formdata.append('password',this.state.password)
             formdata.append('name',this.state.name)
             axios.post('/api/signup' , formdata)

             .then(response=> {
                        if(response.data.type === 'error'){
                             cogoToast.error(response.data.content)
                               this.setState({
                               captcha : '',
                               bs : true
                             })
                        }else{
                          this.props.history.push('/Auth')

                        }

             });
         }
         onChange = (e) => {
           const value = e.target.value

              this.setState({
                   [e.target.name]: e.target.value
              })
         }
      captcha =(value) =>{
      this.setState({captcha : 'value' })
    }
componentDidMount(){

}

  render(){
    return(
      <>
        <div className='bg-auth text-white'>
        <Row>

          <Col className='m-auto'  xs='12' sm="12" md='10' lg={{ size: 4, offset: 4 }} xl={{size:4, offset: 4 }} >

            <Row className='auth-wrapper'>

               <Col className='m-auto rtl bg-transparent-auth'>
                <div className='m-auto text-center' >
                 <Link to='/' className='text-center'>
                  <img src={Pay} width='65px' className='mt-2' />
                 </Link>
                 </div>
                <h5 className='text-center my-2'>برای ثبت نام مشخصات خود را وارد  کنید</h5>
               <Form>
               <FormGroup>
                  <Label for="name">نام کامل</Label>
                  <Input onChange={this.onChange} type="text" name="name" id="name" />
                  {this.validator.message('name', this.state.name, 'required')}
                </FormGroup>
               <FormGroup>
                  <Label for="email">ایمیل</Label>
                  <Input onChange={this.onChange} type="email" name="email" id="email" />
                  {this.validator.message('email', this.state.email, 'email|required')}
                </FormGroup>
                <FormGroup>
                  <Label for="Password">رمز عبور</Label>
                  <Input onChange={this.onChange} type="password" name="password" id="Password" />
                  {this.validator.message('password', this.state.password, 'required|min:4|max:8')}
                </FormGroup>
                <FormGroup>
                <ReCAPTCHA
                    required
                    sitekey="6Lf-3OYcAAAAAIVT5wPV2GEpo_xP4oOlixoflO0o"
                    onChange={this.captcha}
                    onExpired={this.cerror}
                    onErrored={this.cerror}
                  />
                  </FormGroup>
               <FormGroup>
                <Button color='primary' onClick={this.onSubmit} className='m'>ثبت نام</Button>
               </FormGroup>
               </Form>
                <div className='d-inline d-flex justify'>
                 <p>اگر حساب کاربری دارید وارد شوید</p>
                 <Link to='/Auth'>ورود</Link>
                </div>
               </Col>

           </Row>
          </Col>
        </Row>
        </div>
      </>
    )
  }
}
export default Home
