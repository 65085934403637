import React from 'react'
import axios from 'axios'
import{Row,Col,Button,DropdownItem} from 'reactstrap'
import {Link} from 'react-router-dom'
import Navpanel from './navbarpanel'
import Pay from '../css/logo.svg'
import {BsShop} from 'react-icons/bs'
import SimpleLineIcon from 'react-simple-line-icons'
class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){

}

  render(){
    return(
      <>
      <Navpanel className='d-none d-sm-block topnav'/>
      <div>
      <div className='panel'/>
      <div className='rtl panel-wrapper mx-3 mb-5'>
      {this.props.children}
      </div>
        <div className='d-none d-sm-block'>
        <div className='sidebar-sticky sidebar menu h-100'>
        <div className='my-4 text-center mt-5' >
         <Link to='/panel/Dashboard' className='text-center pt-3'>
          <img src={Pay} width='65px' />
         </Link>
         </div>
        <ul class="nav flex-column">
          <li class="nav-item">
            <Link class="nav-link d-flex" to="/panel/Dashboard">
              <span><SimpleLineIcon name='grid ml-3' /></span>
              داشبورد
            </Link>
          </li>
          <DropdownItem divider className='divider-menu' />
          <li class="nav-item">
            <Link class="nav-link d-flex" to="/panel/Profile">
              <span><SimpleLineIcon name='user ml-3' /></span>
              پروفایل
            </Link>
          </li>

          <DropdownItem divider className='divider-menu ' />
          <li class="nav-item">
            <Link class="nav-link d-flex" to="/panel/Products">
              <span><SimpleLineIcon name='credit-card ml-3' /></span>
              فروشگاه
            </Link>
          </li>
          <DropdownItem divider className='divider-menu ' />
          <li class="nav-item">
            <Link class="nav-link d-flex" to="/panel/Wallet">
              <span><SimpleLineIcon name='wallet ml-3' /></span>
              کیف پول
            </Link>
          </li>
          <DropdownItem divider className='divider-menu ' />
          <li class="nav-item">
            <Link class="nav-link d-flex" to="/panel/Orders">
              <span><SimpleLineIcon name='list ml-3' /></span>
              سفارشات
            </Link>
          </li>


        </ul>
        </div>
        </div>

        </div>

      </>
    )

  }
}
export default Home
