import React, { Suspense } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import Dashboard from './pages/dashboard'
import Profile from './pages/profile'
import Products from './pages/products'
import Orders from './pages/orders'
import Transactions from './pages/transactions'
import Message from './pages/messages'
import Wallet from './pages/wallet'
import Orderdetail from './pages/ordersdetail'
import Home from './pages/home'

import AppLayout from './pages/panel2';


const App = ({ match }) => {
  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Suspense fallback={<div className="loading" />}>
          <Switch>

            <Route
              path={`${match.url}/Dashboard`}
              render={(props) => <Dashboard {...props} />}
            />
            <Route
              path={`${match.url}/Profile`}
              render={(props) => <Profile {...props} />}
            />
            <Route
              path={`${match.url}/Products`}
              render={(props) => <Products {...props} />}
            />
            <Route
              path={`${match.url}/Orders`}
              render={(props) => <Orders {...props} />}
            />
            <Route
              path={`${match.url}/Messages`}
              render={(props) => <Message {...props} />}
            />
            <Route
              path={`${match.url}/Wallet`}
              render={(props) => <Wallet {...props} />}
            />
            <Route
              path={`${match.url}/Transactions`}
              render={(props) => <Transactions {...props} />}
            />
            <Route
              path={`${match.url}/orderdetail/:id`}
              render={(props) => <Orderdetail {...props} />}
            />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </AppLayout>
  );
};

export default withRouter(App);

function NoMatch() {
return (
<div className='text-center my-5 mx-5'>
<h3>
NOT FOUND
</h3>
<h1>404</h1>
</div>
);
}
