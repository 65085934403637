import React from 'react'
import axios from 'axios'
import cogoToast from 'cogo-toast'
import cookie from 'react-cookies'
import authdoc from './authdoc.svg'
import {Row,Col,List,FormGroup,Button, Form,Label,  FormFeedback, FormText,Input,Modal,ModalHeader,
ModalBody,Card,
ModalFooter} from 'reactstrap'
import SimpleLineIcon from 'react-simple-line-icons'
import profileimg from './profile.png'
import SimpleReactValidator from 'simple-react-validator'
import Dropzone from 'react-dropzone'
class profile extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
            emailverify : "",
            accounts:[],
            imgSrc : '',
            mt : false,
            mbd : 'sdd',
            tab :'whatsapp',
            files: [],
            infomodal : false

          }
            this.validator = new SimpleReactValidator();
   }
  onDropdocid = (files) => {
      this.setState({files})


    };

   onChange = (e) => {
        this.setState({
             [e.target.name]: e.target.value
        })
   }
   onSubmit = (e) => {
       e.preventDefault();
       if (!this.validator.fieldValid('name')) {
         cogoToast.error('نام را وارد کنید')
         return
      }else{

      }
      if (!this.validator.fieldValid('bname')) {
        cogoToast.error('نام بانک را وارد کنید')
  return
      }else{

      }
      if (!this.validator.fieldValid('card')) {
        cogoToast.error('شماره کارت را وارد کنید')
return
      }else{

      }
      if (!this.validator.fieldValid('iban')) {
        cogoToast.error('شماره شبا را وارد کنید')
return
      }else{

      }
       this.setState({button:""})
       const formData = new FormData();
     formData.append('name' , this.state.name)
     formData.append('bname' ,this.state.bname)
     formData.append('card' ,this.state.card)
     formData.append('iban' ,this.state.iban)
       axios.post('/api/Finance/AddAccount',formData).then(response=>{
         if(response.data.status === 'success'){
         this.setState({button:"ok"})
         this.getaccounts()

         }else{
           this.setState({button:"ok" , verified : "ok"})

         }
       })
   }
   getaccounts=()=>{
     axios.post('/api/Finance/GetAccounts').then(response=>{
         if(response.data.nums === 0){
           this.setState({message : response.data.message})
         }else{
           this.setState({accounts : response.data})
         }
       }
     )
   }
   componentWillMount() {

   }
   componentDidMount(){
        axios.post('/api/Profile/getstate').then(response=>{
          this.setState({data : response.data , name : response.data.data.name, email : response.data.data.email , dv :response.data.data.dv})
          })
      }
      t1=()=>{
        this.setState({t1 :!this.state.t1})
      }
      t2=()=>{
        this.setState({t2 :!this.state.t2})
      }
      t3=()=>{
        this.setState({t3 :!this.state.t3})
      }
      t4=()=>{
        this.setState({t4 :!this.state.t4})
      }
      toggle=()=>{
        this.setState({tab : !this.state.tab})
      }
      submitemailcode=()=>{
        axios.post('/api/Verify/emailverify',{code : this.state.codemail}).then(response=>{
          if(response.data.status === 'error'){
            cogoToast.error(response.data.message)
          }else if(response.data.status === 'success'){
            cogoToast.success(response.data.message)
            this.setState({t1 : !this.state.t1})
            this.props.update()
          }
        })
      }
      submitmobile=()=>{
        if (!this.validator.fieldValid('mm')) {
          cogoToast.error('شماره موبایل معتبری وارد کنید')
          return
       }else{

       }
       if (this.state.mm.length !== 11) {
         cogoToast.error('شماره موبایل باید 11 رقم باشد')
         return
      }else{

      }
       this.setState({mbd : ''})
        axios.post('/Users/submitmobile',{mobile : this.state.mm}).then(response=>{
          if(response.data.status === 'error'){
            this.setState({mbd : ''})
            cogoToast.error(response.data.message)
          }else if(response.data.status === 'success'){
            cogoToast.success(response.data.message)
            this.setState({t2 : !this.state.t2})
            // this.props.update()
          }
        })
      }
      submitotp=()=>{
        axios.post('/api/Verify/verifymobile',{code : this.state.otp}).then(response=>{
          if(response.data.status === 'error'){
            this.setState({mbd : ''})
            cogoToast.error(response.data.message)
          }else if(response.data.status === 'success'){
            cogoToast.success(response.data.message)
            this.setState({t4 : !this.state.t4})
            this.props.update()
          }
        })
      }
      onSubmitdoc =(e) =>{
        if(this.state.files.length === 0){
          cogoToast.error('فایل انتخاب نشده است')
          return
        }
        if(this.state.files.length > 1){
          cogoToast.error('مجاز به انتخاب یک فایل هستید')
         this.setState({files : []})
          return
        }
        if(this.state.files[0].size > 20000){
          cogoToast.error('حجم فایل بیش از حد مجاز است.')
         this.setState({files : []})
          return
        }
         e.preventDefault()
         const formData = new FormData();
         formData.append('avatar',this.state.files[0])
         formData.append('uid',cookie.load('uid'))
         axios.post('/api/Docs/Upload_Image', formData,{
             headers: {
                 'content-type': 'multipart/form-data'
             }
         }).then(response=>{
           if(response.data.status === 'success'){
             cogoToast.success('فایل با موفقیت ارسال شد.')
             this.setState({files : [],t3 : !this.state.t3})
             this.props.update()
           }else{
             cogoToast.error('فایل ارسال نشد.')
           }
         })
     }
     setinfomodal = ()=>{
       this.setState({infomodal : !this.state.infomodal})
     }
     render() {
       const accounts = this.state.accounts.map(account =>(
         <li key={account.id}>
            <span className='mx-2'>{account.bankname}</span> -<span className='mx-3'> {account.accountNo}  </span>
         </li>

       ))
       const files = this.state.files.map(file => (
     <li key={file.name}>
       بایت {file.name} - {file.size}
     </li>
   ));
        return(
          <div>
          <Row xl='2' md='2' sm='1' xs='1' className=' rtl'>
              <Col xl='2' md='4' className=' rtl'>

              <div className='text-center'>
                <img src={profileimg} className='profile-img m-auto mt-3' width='150'  tag={this.state.name}/>
              </div>
              </Col>


              <Col xl='4' md='8' className=' rtl float-right'>
              <h5 className='mx-2'>وضعیت کاربری</h5>
              <List type="unstyled">
             <p className='mx-2'> ایمیل : {this.state.email}</p>
              <p className='mx-2'>نام : {this.state.name}</p>
              <p className='mx-2'>سطح کاربری  : 3</p>
              <p className='mx-2'>مدارک هویتی : درحال بررسی</p>




              </List>
              </Col>
              <Col>
              {this.state.dv === '0' ?
              <>
              <p className='mb-3'>ارسال تصویر مدارک<span onClick={this.setinfomodal} className='text-small text-primary mx-2'>  راهنمای ارسال</span></p>
            <Dropzone  onDrop={this.onDropdocid.bind(this)}  multiple={false}>
              {({getRootProps, getInputProps}) => (
                  <section className= 'dropzone'>
                  <div {...getRootProps({})}>
                   <input {...getInputProps()} />
                   <p className='text-muted rtl m-3 text-small'>برای ارسال تصویر کارت ملی کلیک کنید یا فایل را به این قسمت بکشید</p>
                   <p className='text-muted rtl mx-3 text-small'>فرمت های مجاز : jpg,jpeg,png</p>
                   <p className='text-muted rtl mx-3 text-small'>حداکثر حجم مجاز : 4 مگا بایت</p>
                  </div>
                  {this.state.files.length === 1 ?
                  <li>{this.state.files[0].name}</li>: <></> }
                  </section>
             )}

            </Dropzone>
            <Button color='dark' className='my-2 px-5 mr-auto ml-auto' >ارسال مدارک</Button>
            </>
            :<></>
            }
              {this.state.dv === '1' ?
              <>
             <p className='text-primary cp mx-3' >مدارک شما در حال بررسی است.</p>
            </>
            :<></>
            }
              {this.state.dv === '2' ?
              <>
             <p className='text-primary cp mx-3' >مدارک شما تائید شده اند</p><SimpleLineIcon  name='exclamation' color='blue'/>
            </>
            :<></>
            }
              </Col>

          </Row>
          <hr/>
          <Row xl='2' md='2' sm='1' xs='1' className='hv100  rtl'>
            <Col className=' rtl' xl='4'>
              <h5 className=' p-3'>ثبت حساب بانکی</h5>
              <div className='p-2'>
              <Form>
              <Input
                 id="email"
                 component="input"
                 name="email"
                 className="rtl my-3"
                 type="email"
                 placeholder='ایمیل'
                 onChange={this.onChange}
               />
                  <FormGroup className='my-2'>

                   <Input placeholder='نام کامل' value={this.state.name} onChange={this.onChange} name="name" />
                     {this.validator.message('name', this.state.name, 'string|required')}
                  </FormGroup>
                  <FormGroup className='my-2'>
                   <Input placeholder='نام بانک'  value={this.state.bname} onChange={this.onChange} name="bname" />
                     {this.validator.message('bname', this.state.bname, 'string|required')}
                  </FormGroup>
                  <FormGroup className='my-2'>
                  <Input placeholder='شماره کارت'  value={ this.state.card} onChange={this.onChange} name="card" />
                   {this.validator.message('card', this.state.card, 'numeric|required|card_num|size:16')}
                  </FormGroup>
                  <FormGroup className='my-2'>
                  <Input placeholder='شماره شبا-بدون IR'  value={this.state.iban} onChange={this.onChange} name="iban" />
                  {this.validator.message('iban', this.state.iban, 'numeric|required')}
                  </FormGroup>
                  <FormGroup>
                  <Button block color='primary' onClick={this.onSubmit} className ="mt-4">ثبت حساب جدید</Button>
                  </FormGroup>
              </Form>
              </div>
            </Col>

            <Col>
            <h5 className=' p-3'>حساب های بانکی</h5>
            <div className='p-2'>
            <p>{this.state.message}</p>
            {accounts}
            </div>
            </Col>

          </Row>
          <Modal isOpen={this.state.t1} >
            <ModalHeader className='rtl' >تایید ایمیل</ModalHeader>
            <ModalBody>
              <Input
              value = {this.state.codemail}
              className='rtl'
              name = 'codemail'
              placeholder='کد تایید'
              onChange={this.onChange}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.submitemailcode} color="success" >ثبت</Button>{' '}
            <Button onClick={this.t1} color="primary" >لغو</Button>{' '}


            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.t2} >
            <ModalHeader className='rtl' >ثبت موبایل</ModalHeader>
            <ModalBody>
              <Input
              value={this.state.mm}
              name='mm'
              className='rtl'
              placeholder='شماره موبایل'
               onChange={this.onChange}
              />
               {this.validator.message('mm', this.state.mm, 'numeric|size=11|required')}
            </ModalBody>
            <ModalFooter>
              <Button disabled={!this.state.mbd} onClick={this.submitmobile} color="success" >ثبت</Button>{' '}
            <Button onClick={this.t2} color="primary" >لغو</Button>{' '}


            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.t3} >

            <ModalBody>
            <div className='text-center inline d-flex' style={{'justify-content': 'space-around'}}>
            <Button onClick={()=>this.setState({tab : 'document'})} color='primary' outline block>ارسال مدارک</Button>
            <Button onClick={()=>this.setState({tab : 'whatsapp'})} color='success' outline block>واتساپ</Button>
            </div>
            <div>
            {this.state.tab === 'whatsapp' ?
              <>
              <p className='rtl d-1 my-3'><small>با ارسال درخواست از طریق واتساپ نیازی به ارسال مدارک نبوده و در اولین فرصت  با واتساپ شماره ای که ثبت شده تماس گرفته خواهد شد</small></p>
              <Button block color='success'  onClick={this.wreq}>ثبت درخواست</Button>
              </>
              :
              <Dropzone onDrop={this.onDrop}>
              {({getRootProps, getInputProps}) => (
              <section className="container rtl">
              <div {...getRootProps({className: 'profile-img'})}>
              <input {...getInputProps()} />
              <p className="m-2"><small>فایل خود را به اینجا بکشید یا برای انتخاب کلیک کنید</small></p>
                <p  className="m-2"><small>فقط یک فایل در فرمت های jpg-jpeg-png  میتوانیید بفرستید</small></p>
              </div>
              <aside>
              <h4>فایل :</h4>
              <ul>{files}</ul>
              </aside>
              </section>
              )}
              </Dropzone>}
            </div>
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.onSubmitdoc} color="success" >ارسال</Button>{' '}
            <Button onClick={this.t3} color="primary" >لغو</Button>{' '}


            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.infomodal}  className="borderless modal-fullscreen">
            <ModalHeader className='rtl' ><span className='rtl c-i mr-3 mt-2'>راهنمای ارسال مدارک</span></ModalHeader>
            <ModalBody>
            <Row xl='2' lg='2' md='1' sm='1' xs='1' className='rtl'>
              <Col>
                <List>
                  <ul className='lh-xl'>
                    <li>برای استفاده از خدمات زرین تتر احراز هویت کاربر الزامی میباشد .
                    </li>
                    <li>برای ارسال مدارک روی یک صفحه کاغذ با خطی خوانا بنویسید برای احراز هویت در سایت زرین تتر.
                    </li>
                    <li>کارت بانکی و کارت شناسایی(کارت ملی ،گواهینامه یا شناسنامه) را پایین متن بچسبانید و یک سلفی بگیرید و ارسال کنید .
                    </li>
                    <li>برای امنیت اطلاعات حتما cvv2 و تاریخ انقضاء روی کارت بانکی را بپوشانید.
                    </li>
                    <li>دقت کنید مدرک شناسایی باید عکسدار باشد .
                    </li>
                    <li>کیفیت عکس را کنترل کنید که کاملا خوانا باشد .
                    </li>
                    <li>از ویرایش عکس ارسالی با ابزارهای گرافیکی خودداری کنید .
                    </li>
                    <li>پس از تایید مدارک حساب کاربری شما قابل استفاده میباشد.
                    </li>
                  </ul>
                </List>
              </Col>
              <Col xl='6' className='text-center'>
              <img src={authdoc} alt='ارسال-مدارک-زرین-استور' width='50%' className='sample-img'/>
              </Col>
            </Row>
            </ModalBody>
            <ModalFooter>
            <Button onClick={this.setinfomodal} color="primary" >متوجه شدم</Button>{' '}


            </ModalFooter>
          </Modal>
          </div>
        )
     }
}
export default profile
