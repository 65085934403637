import React from 'react'
import axios from 'axios'
import{Row,Col,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Link} from 'react-router-dom'
import Pay from '../css/logo.svg'
class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){

}

  render(){
    return(
      <>
      <div className='bg-auth text-white'>
      <Row>

        <Col className='m-auto'  xs='12' sm="12" md='10' lg={{ size: 4, offset: 4 }} xl={{size:4, offset: 4 }} >

          <Row className='auth-wrapper'>

             <Col className='m-auto rtl bg-transparent-auth p-5'>
               <div className='m-auto text-center' >
                <Link to='/' className='text-center'>
                 <img src={Pay} width='65px' />
                </Link>
                </div>
               <h5 className='text-center my-5'>ایمیل خود را برای ارسال رمز وارد کنید</h5>
               <Form>
               <FormGroup>
                  <Label for="email">ایمیل</Label>
                  <Input type="email" name="email" id="email" />
                </FormGroup>
                <FormGroup>
                <Button color='primary' className='m'>ارسال رمز عبور</Button>
               </FormGroup>
               </Form>
               <div className='d-inline d-flex justify'>
                <p>بازگشت به صفحه ورود</p>
                <Link to='/Auth'>ورود</Link>
               </div>
               </Col>
           </Row>
          </Col>
        </Row>
        </div>
      </>
    )
  }
}
export default Home
