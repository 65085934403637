import React from 'react'
import axios from 'axios'
import {
CardHeader,
CardBody,
CardText,
Card,Container,Col,Row,Button,Table,FormGroup,Input} from 'reactstrap'
import cogoToast from 'cogo-toast'
import cookie from 'react-cookies'
import {Link} from 'react-router-dom'
import SimpleLineIcon from 'react-simple-line-icons'
import NumericFormat from 'react-number-format';

class register extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               txs: []

          }
     }
     onChange = (e) => {
          this.setState({
               [e.target.name]: e.target.value
          })
     }

     componentDidMount(){

       axios.get('/api/users/getuserbalance').then(response=>{
         if(response.data.status === 'error-fatal'){
           this.props.history.push('/')
         }
         this.setState({usd : response.data.usd , products : response.data.products , balance : response.data.balance})
       })


     }
     render() {

          return (
  <Container dir="rtl" style={{'margin-top':'40px'}}>
  <Card>
  <CardHeader className='text-info' tag="p">کیف پول</CardHeader>
  <CardBody>

  <Row xl="1" md='1' sm='1' xs='1'>
  <Col xl='12'>
    <div>
    <Row className='rtl'>
      <Col xl={{size:8 , offset :2}} className='m-auto'>
      <div className='info-main my-3'>
      <p className='text-medium c-i'> مبلغی را که میخواهید شارژ نمایید را وارد کنید.</p>
      <p className='text-medium c-i inline-space d-flex justify-content-space-around'>موجودی : <Link className='font-weight-bold text-medium mx-3'> <NumericFormat value={parseInt(this.state.balance)}  thousandSeparator="," displayType="text"  className='font-weight-bold' /> تومان</Link> <small className='pointer mb-1 d-flex'><SimpleLineIcon name='refresh mx-2' color='indigo'  onClick={this.refresh} />بروزرسانی</small></p>
      </div>
      <FormGroup>
      <Input type='number' min={0}  name='amount' onChange={this.onchange} />
      </FormGroup>
      <FormGroup className='button-container'>
      <Button color='dark' block onClick={this.chargewallet}>شارژ کیف پول</Button>
      </FormGroup>
      </Col>
    </Row>
    </div>

  </Col>

  </Row>
  </CardBody>


  </Card>
  </Container>

     )

}}
export default register
