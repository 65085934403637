import React from 'react'
import axios from 'axios'
import{Row,Col,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import {Link} from 'react-router-dom'
import Pay from '../css/logo.svg'
import SimpleReactValidator from 'simple-react-validator'
import cogoToast from 'cogo-toast'
class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={
      email : 'test@test.com',
  password : 'admin'
    }
this.validator = new SimpleReactValidator();
  }
componentDidMount(){

}
Login = (e) => {
    e.preventDefault();
this.props.history.push('/panel/dashboard')

          if (!this.validator.fieldValid('email')) {
               cogoToast.error('ایمیل معتبری وارد کنید')
               return
             }else{

             }
             if (!this.validator.fieldValid('password')) {
                  cogoToast.error('رمز عبور باید بیشتر از 4 کاراکتر باشد')
                  return
                }else{}
                     this.setState({active2 : false})
    const formdata = new FormData()
    formdata.append('email',this.state.email)
    formdata.append('password',this.state.password)
    axios.post('/api/signin' , formdata)
    .then(response=> {
               if(response.data.type === 'error'){
                    cogoToast.error(response.data.content)
                      this.setState({active2 : true})
               }else{
                 this.setState({active2 : true,status : 'ثبت'})
                  cogoToast.success(response.data.content)

                  console.log(this.props)
                  this.props.history.push("/Panel/products")
               }

    });
}
onChange = (e) => {
  const value = e.target.value

     this.setState({
          [e.target.name]: e.target.value
     })
}
  render(){
    return(
      <>
        <div className='bg-auth text-white'>
        <Row>

          <Col className='m-auto'  xs='12' sm="12" md='10' lg={{ size: 4, offset: 4 }} xl={{size:4, offset: 4 }} >
            <Row className='auth-wrapper'>
               <Col className='m-auto rtl bg-transparent-auth p-5'>
                <div className='m-auto text-center' >
                 <Link to='/' className='text-center'>
                  <img src={Pay} width='65px' />
                 </Link>
                 </div>
               <h5 className='text-center my-5'>برای ورود مشخصات خود را وارد کنید</h5>
               <Form>
               <FormGroup>
                  <Label for="email">ایمیل</Label>
                  <Input value={this.state.email} onChange={this.onChange} type="email" name="email" id="email" />
                  {this.validator.message('email', this.state.email, 'email|required')}
                </FormGroup>
                <FormGroup>
                  <Label for="Password">رمز عبور</Label>
                  <Input value={this.state.password} onChange={this.onChange} type="password" name="password" id="Password" />
                  {this.validator.message('password', this.state.password, 'required|min:4')}
                </FormGroup>
               <FormGroup>
                <Button onClick={this.Login} color='primary'>ورود</Button>
               </FormGroup>
               </Form>
               <div className='d-inline d-flex justify'>
                <p>حساب کاربری ندارید؟</p>
                <Link to='/SignUp'>ثبت نام</Link>
               </div>
               <Link to='/Recover-Password'>بازیابی رمز عبور</Link>
               </Col>
           </Row>
          </Col>
        </Row>
        </div>
      </>
    )
  }
}
export default Home
