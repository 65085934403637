import React from 'react'
import axios from 'axios'
import{Row,Col,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import { BsApple,BsGoogle } from "react-icons/bs";
import {FaXbox,FaGooglePlay,FaPlaystation,FaHandsHelping} from "react-icons/fa"
import {SiNetflix,SiFsecure,SiFastly} from "react-icons/si"
import {Link} from 'react-router-dom'
import Pay from '../images/pngwing.com.png'
import Navber from './navbar'
import Slider from '../components/slider2'
import payment from '../images/payment.png'
import Footer from './footer'
class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){

}

  render(){
    return(
      <>

      <div className=''>
        <Navber/>
      <div >
      <h5 className='rtl pr-5 my-5'>درباره ما</h5>
        <div className='px-5 rtl py-5'>
          <p>
فروشگاه گیف کارت  رپیت اکس استور برای سهولت در خرید بین المللی و دور زدن تحریم های علیه ایرانیان و تابع قوانین جمهوری اسلامی ایران بوده وبرای تسهیل پرداخت های یا نقد کردن درآمد های ارزی راه اندازی شده است.
          </p>
        </div>
        <div className='footer'>
        <Footer/>
        </div>
      </div>
      </div>

      </>
    )
  }
}
export default Home
