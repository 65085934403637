import React from 'react'
import axios from 'axios'
import{Row,Col,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import { BsApple,BsGoogle } from "react-icons/bs";
import {FaXbox,FaGooglePlay,FaPlaystation,FaHandsHelping} from "react-icons/fa"
import {SiNetflix,SiFsecure,SiFastly} from "react-icons/si"
import {Link} from 'react-router-dom'
import Pay from '../images/pngwing.com.png'
import Navber from './navbar'
import Slider from '../components/slider2'
import payment from '../images/payment.png'
import Footer from './footer'
class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){

}

  render(){
    return(
      <>

      <div className=''>
        <Navber/>
      <div >
      <Row className='rtl'>

        <Col className=' my-3 rtl hl-xl' >
        <h3 className=' my-3'>قوانین و مقررات</h3>
        <p className='mx-3'>
        1- فروشگاه رپیت اکس استور از ارائه خدمات به افراد با هویت مجهول و مشکوک معذور است.
        </p>
        <p className='mx-3'>
        2-ارائه خدمات فقط در صورت ارائه مدارک شناسائی معتبر اعم از کارت ملی,شناسنامه و گذرنامه امکان پذیر است.
        </p>
        <p className='mx-3'>
        3-حساب کاربری افرادی که اطلاعات کاربری خود را در اختیار دیگران قرار میدهند یا از حساب های دیگران برای واریز و برداشت استفاده میکنند را مسدود و خواهد کرد و برداشت موجودی فقط در صورت ارائه مدارک صاحب ممکن خواهد بود.
        </p>
        <p className='mx-3'>
        4-در صورت استفاده از خدمات رپیت اکس استور در سایت های شرط بندی و مغایر با قوانین جمهوری اسلامی ایران حساب کاربری مسدود و مراتب متعاقبا به پلیس فتا اطلاع داده خواد شد.
        </p>
        <p className='mx-3'>
        5-ثبت نام در این وبسایت به منزله پذیرش این قوانین تلقی و اعتراض بعدی به هیچ وجه پذیرفته نیست.
        </p>
        <p className='mx-3'>
        6-واریز و برداشت فقط با کارت بانکی که در مراحل احراز هویت اخذ شده امکان پذیر است.
        </p>
        <p className='mx-3'>
        7-با توجه به اینکه رمز عبور به صورت کدگذاری شده میباشد لذا در صورت استفاده دیگر اشخاص از حساب کاربری شما هیچ مسئولیتی از طرف رپیت اکس استور پذیرفته نخواهد بود.
        </p>
        <p className='mx-3'>
        8-تمامی مشخصات تماس و آدرس و آی-پی ما در نزد پلیس فتای استان آذربایجان شرقی موجود بوده و در صورت هرگونه شکایت رپیت اکس استور مدارک مربوطه را به پلیس فتا و مراجه قضایی ارائه خواد کرد.
        </p>
        <p className='mx-3'>
        9-از ارائه خدمات انتقال وجه به صورت حواله ارزی معذوریم و فقط برای سهولت ایرانیان عزیز برای خدمات ذکر شده خدمات ارائه خواهد شد.
        </p>
        <p className='mx-3'>
        10-تمامی تراکنش ها با ارسال کد امنیتی به مشخصات ثبت شده ممکن بوده لذا از در دسترس قرار دادن این اطلاعات به افراد دیگر خودداری نمائید.
        </p>
        <p className='mx-3'>11-
        بدلیل خرید از سایت های خارجی و تحویل خدمات یا کالا در هنگام سفارش دقت لازم را به عمل آورده و یا قبل از سفارش در خصوص نوع کالا یا خدمات مشاوره دریافت نمائید.بعد از ثبت سفارش به هیچ عنوان کالا مرجوع نشده و مبلغی عودت داده نمیشود.
        در صورت  عدم ارسال کالا تا 24 ساعت سفارش لغو و مبلغ به کیف پول کاربری در رپیت اکس برگشت داده میشود.

        </p>
        <p className='mx-3'>12-
          خدمات و کالاهای موجود در رپیت اکس استور مطابق قوانین جمهوری اسلامی ایران بوده و شامل گیفت کارت ها یا ووچر هایی میباشد که صرفا جهت تسهیل امورات دانشجویی یا خرید از سایت های خارجی و یا نقد کردن درآمد های ارزی ایرانیان عزیز میباشد.
        </p>
        <p className='mx-3'>13-
        خدمات یا کالای خریداری شده حداکثر تا 24 ساعت پس از خرید یه ایمیل کاربری شما ارسال میشود.از در اختیار قرار دادن کد و یا رمز های مربوط به کالا و خدمات به دیگران اکیدا خودداری کنید.مسئولیت استفاده و یا سرقت خدمات و کالا ها پس از ارسال به ایمیل به عهده مشتری بوده و سایت رپیت اکس در این زمینه هیچ گونه مسئولیتی قبول نخواهد کرد.
        </p>
        <p className='mx-3'>14-
        در صورت بروز هرگونه تخلف در استفاده از خدمات و کالا ها حساب کاربری مسدود خواهد شد.
        </p>
        <p className='mx-3'>15-
          با توجه به دیجیتالی بودن تمامی کالاها هیچ گونه مرجوع یا برگشت وجه امکان پذیر نیست.
        </p>

        <p className='mx-3'>16-
        برای مشاوره در خرید و یا ثبت کالا های دیگر با شماره تلفن 04133191672 و یا آدرس ایمیل INFO@ZARRINTETHER.STORE  تماس حاصل نمایید.
        </p>

        </Col>
      </Row>
        <div className='footer'>
        <Footer/>
        </div>
      </div>
      </div>

      </>
    )
  }
}
export default Home
