import React from 'react'
import axios from 'axios'
import{Form,FormGroup,Input,Label,Row,Col,Button,Card,CardTitle,CardText,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {Link} from 'react-router-dom'
import SimpleLineIcon from 'react-simple-line-icons'
import { BsApple,BsGoogle } from "react-icons/bs";
import {FaXbox,FaGooglePlay,FaPlaystation} from "react-icons/fa";
import {SiNetflix} from "react-icons/si"
import { RiEmotionUnhappyLine } from "react-icons/ri";
import NumberFormat from 'react-number-format'
class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={
      mess:[],
      trss :[],
      modal : false,
      currentp:'',
      usd : 30000
    }

  }
componentDidMount(){

  axios.get('/Users/GetBalance').then(response=>{
    this.setState({balance : response.data.balance})
  })
}
setvalue=(e)=>{
  this.setState({value : parseInt(e.currentTarget.getAttribute('data-price')) , amount : e.currentTarget.getAttribute('data-price')})
}
  render(){
    return(
      <>
        <div className='d-flex justify'>
          <span onClick={this.setvalue}  data-price={10} className='p-v-value'>10 $</span>
          <span onClick={this.setvalue}  data-price={25} className='p-v-value'>25 $</span>
          <span onClick={this.setvalue}  data-price={50} className='p-v-value'>50 $</span>
          <span onClick={this.setvalue}  data-price={100} className='p-v-value'>100 $</span>
        </div>
          {this.state.value ?   <li className='m-3 rtl'>گیفت کارت پلی استیشن {this.state.amount} دلاری</li>  : <li className='m-3 rtl'>گیفت کارت پلی استیشن</li>}
        <div className='m3-5 rtl d-flex'>
        <li className='mx-3 justify'>مقدار تراکنش:</li>
        <NumberFormat value={this.state.value} displayType={'text'} thousandSeparator={true} />
      {this.state.value ?   <p className='mx-3'>تومان</p> : ''}
        </div>
        <hr/>
        <Button outline color='info' block>ثبت سفارش</Button>
      </>
    )
  }
}
export default Home
