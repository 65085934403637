import React from 'react'
import axios from 'axios'
import Home from './pages/home'
import Auth from './pages/auth'
import Signup from './pages/signup'
import Forgot from './pages/forgot'
import Panel from './proute'
import Roules from './pages/roules'
import Contact from './pages/contact'
import Comp from './pages/complaining'
import About from './pages/about'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  useHistory,
} from 'react-router-dom'
class App extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){

}

  render(){
    return(
      <div>
      <Router>
        <Switch>
          <Route
            path='/' exact
            render={(props) => <Home {...props} />}
          />
          <Route
            path='/Auth'
            render={(props) => <Auth {...props} />}
          />
          <Route
            path='/signup'
            render={(props) => <Signup {...props} />}
          />
          <Route
            path='/about-us'
            render={(props) => <About {...props} />}
          />
          <Route
            path='/contact-us'
            render={(props) => <Contact {...props} />}
          />
          <Route
            path='/complaining'
            render={(props) => <Comp {...props} />}
          />
          <Route
            path='/Roules'
            render={(props) => <Roules {...props} />}
          />
          <Route
            path='/panel'
            render={(props) => <Panel {...props} />}
          />

        </Switch>
      </Router>
      </div>
    )
  }
}
export default App
