import React from 'react'
import {Row,Col} from "reactstrap";
import SimpliLineIcon from 'react-simple-line-icons'
class Footer extends React.Component{
render() {

 return (
   <div className='rtl footer text-dark'>
    <Row>
    <Col className='d-none' xl='2'>
    <a  target="_blank" href="https://trustseal.enamad.ir/?id=328344&Code=B31ZUK4M10Tb4u692paU"><img referrerpolicy="origin" src="https://trustseal.enamad.ir/?id=328344&Code=B31ZUK4M10Tb4u692paU" alt="اینماد رپیتکس" className='pointer' id="B31ZUK4M10Tb4u692paU" /></a>
    </Col>
    <Col xl='4' className=''>
    <p className='text-dark font-weight-bold mx-auto' >تماس با ما</p>
    <p className='d-flex '><span><SimpliLineIcon name='phone'/></span><span className='mx-2'>04133191672</span></p>
    <p className='d-flex '><span><SimpliLineIcon name='phone'/></span><span className='mx-2'>09211297527</span></p>
    <p className='d-flex'><span><SimpliLineIcon name='envelope-open'/></span><span className='mx-2'>info@rapitex.org</span></p>
    </Col>
    <Col xl='6'>
    <li>فروشگاه رپیتکس جهت سهولت در پرداخت های بین المللی و نقد کردن در آمد های ارزی بر طبق قوانین بانک مرکزی و قوانین جمهوری اسلامی طراحی شده و در تمامی تراکنش ها تابع قوانین بانک های جمهوری اسلامی ایران میباشد.</li>
    </Col>

    </Row>
    <a referrerpolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=528672&Code=TPjvxJcpSmP2DUR9QHBfzUk1Rd1td1Kg'><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=528672&Code=TPjvxJcpSmP2DUR9QHBfzUk1Rd1td1Kg' alt=''  code='TPjvxJcpSmP2DUR9QHBfzUk1Rd1td1Kg'/></a>
   </div>
 )
}
}
export default Footer
