import React from 'react'
import axios from 'axios'
import {
CardHeader,
CardBody,
CardText,
Card,Container,Col} from 'reactstrap'
import cogoToast from 'cogo-toast'
import cookie from 'react-cookies'
class register extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               txs: []

          }
     }
     onChange = (e) => {
          this.setState({
               [e.target.name]: e.target.value
          })
     }

     componentDidMount(){
       const uid = cookie.load('uid')
       const formdata = new FormData()
       formdata.append('uid',uid)
       axios.post('/api/users/Transactions',formdata).then(response=>{
         this.setState({txs : response.data})
       })
     }
     render() {
          return (
  <div dir="rtl" style={{'margin-top':'40px'}}>
  <Card>
  <CardHeader className='text-success' tag="h3">تراکنش ها</CardHeader>
  <CardBody>
  {this.state.txs.length > 0 ?
    <>
    <p>are data</p>
    <CardText>{this.state.txs.map(tx=>{
      <p>{tx.amount}</p>
    })}</CardText>
    </>
    :
    <p>نراکنشی یافت نشد</p>
  }
  </CardBody>


  </Card>
  </div>

     )

}}
export default register
