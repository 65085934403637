import {Button} from 'reactstrap'
import {Link} from 'react-router-dom'
const Slide1 =()=>{
  return(
    <div className='slide-1'>
      <h1 className='display-1 slide-title'>
        فروشگاه گیفت کارت های معتبر
      </h1>
      <h3>فروشگاه اینترنتی گیفت کارت و کارت شارژ</h3>
      <Link to='/auth' >
      <Button color="light" className='main-block-xl-light shine m-5 '>همین الان شروع کنید</Button>
      </Link>
    </div>
  )
}
export {Slide1};
const Slide2 =()=>{
  return(
    <div className='slide-2'>
      <h1 className='display-1 slide-title'>
        ارائه گیفت کارت و  در کمتر از 1 ساعت
      </h1>
      <h3>خرید  پرفکت با بهترین قیمت ممکن </h3>
      <Link to='/auth' >
      <Button color="light" className='main-block-xl-light shine m-5'>همین الان شروع کنید</Button>
      </Link>
    </div>
  )
}
export {Slide2};
const Slide3 =()=>{
  return(
    <div className='slide-3'>
      <h1 className='display-1 slide-title'>
        خرید برترین گیفت کارت های دنیا
      </h1>
      <h3><span className='mx-1'>گیفت کارت آیتونز</span><span className='mx-5'>گیفت کارت گوگل پلی</span><span className='mx-1'> گیفت کارت آمازون</span></h3>
      <Link to='/auth' >
      <Button color="warning" className='main-block-xl-light shine m-5'>همین الان شروع کنید</Button>
      </Link>

    </div>
  )
}
export {Slide3};
