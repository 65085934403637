import React from 'react';
import axios from 'axios'
import cookie from 'react-cookies'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import SimpliLineIcon from 'react-simple-line-icons'
import {Link} from 'react-router-dom'
class Example extends React.Component {
  exit = ()=>{
    cookie.remove('uid')
    window.location.replace('/')
  }
  render(){
    return (
      <div>
        <Navbar color='light' light fixed='top' expand="md" >


        <div className='mr-auto d-flex px-3' >
          <Link onClick={this.exit} to='#'><p className='d-flex'><SimpliLineIcon name='power mx-2 my-auto' /></p></Link>
          <Link className='mr-auto' to='profile'><p className='d-flex'>{localStorage.getItem('name')}<SimpliLineIcon name='user mx-2 my-auto' /></p></Link>
          </div>
          <div className='ml-auto d-flex px-3' >
          <NavbarBrand className='mr-auto'><Link to='/'><span className='mx-3 my-auto'>رپیت اکس</span></Link></NavbarBrand>

          </div>
        </Navbar>
      </div>
    );
  }
}

export default Example;
