import React from 'react'
import axios from 'axios'
import {
CardHeader,
CardBody,
CardText,
Card,Container,Col,Table} from 'reactstrap'
import cogoToast from 'cogo-toast'
import cookie from 'react-cookies'
import SimpleLineIcon from 'react-simple-line-icons'
class register extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               txs: []

          }
     }
     onChange = (e) => {
          this.setState({
               [e.target.name]: e.target.value
          })
     }

     componentWillMount(){
       const uid = cookie.load('uid')
       const formdata = new FormData()
       formdata.append('uid',uid)
       axios.post('/api/Transactions/getorders',formdata).then(response=>{
         this.setState({txs : response.data})
       })
     }
     viewdetails =(i)=>{
       var id = i.orderid
       var url = 'orderdetail/' + id
       this.props.history.push(url)
     }
     render() {
       console.log(this.state);
          return (
  <div dir="rtl" style={{'margin-top':'40px'}}>
  <Card>
  <CardHeader className='text-info radius' tag="p">تراکنش ها</CardHeader>
  <CardBody>
  {this.state.txs.length > 0 ?
    <>
    <div>
    <Table borderless  responsive size='sm' stripped>
      <thead>
        <tr className='font-weight-bold'>
          <td>
          #
          </td>
          <td>
          کد رهگیری
          </td>
          <td>
          تاریخ
          </td>
          <td>
          مقدار
          </td>
          <td>
          جزئیات
          </td>
        </tr>
      </thead>
      <tbody>
      {
        this.state.txs.map((i,index)=>
          <tr key={i.id}>
              <td >{index}</td>
              <td className='mx-2'>{i.orderid}</td>
              <td className='mx-2'>{i.date}</td>
              <td>{i.amount}</td>
              <td className='mx-2'>{i.orderinfo}</td>
              <td className='mx-2' onClick={()=>this.viewdetails(i)}><SimpleLineIcon name='info pointer' color='green' /></td>
          </tr>
        )
      }
      </tbody>

    </Table>

    </div>
    </>
    :
    <p>نراکنشی یافت نشد</p>
  }
  </CardBody>


  </Card>
  </div>

     )

}}
export default register
