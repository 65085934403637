import React from 'react'
import axios from 'axios'
import{Form,FormGroup,Input,Label,Row,Col,Button,Card,CardTitle,CardText,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {Link} from 'react-router-dom'
import SimpleLineIcon from 'react-simple-line-icons'
import { BsApple,BsGoogle } from "react-icons/bs";
import {FaXbox,FaGooglePlay,FaPlaystation} from "react-icons/fa";
import {SiNetflix} from "react-icons/si"
import { RiEmotionUnhappyLine } from "react-icons/ri";
import Apple from './apple'
import Google from './google'
import Xbox from './xbox'
import Ps from './ps'
import cogoToast from 'cogo-toast'
import Mint from './mint'
import NumberFormat from 'react-number-format'

class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={
      products:[],
      i :[],
      modal : false,
      currentp:'',
      usd : 65000
    }

  }
  setvalue=(e)=>{
    this.setState({value : parseInt(e.currentTarget.getAttribute('data-price')) * this.state.usd , amount : e.currentTarget.getAttribute('data-price')})
  }
  toggle =()=>{
    this.setState({
      modal : !this.state.modal,
      currentp : '',
      amount : '',
      value : '',
      i : [],
    })
  }
    // console.log(e.currentTarget.getAttribute('data-tag'))
componentDidMount(){
  axios.get('/api/users/getuserbalance').then(response=>{
    if(response.data.status === 'error-fatal'){
      this.props.history.push('/')
    }
    this.setState({usd : response.data.usd , products : response.data.products , balance : response.data.balance})
  })
}
modalopen=(e)=>{
  var slug = e.currentTarget.getAttribute('data-pn')
  // const found = this.state.products.find(element => element.slug === slug);
  console.log('found=>',slug);
  // // const i = found.in
  // this.setState({i : found });


  this.setState({
    modal :!this.state.modal,
    slug : slug,
    currentp : e.currentTarget.getAttribute('data-pn')

  })
  // console.log(e.currentTarget.getAttribute('data-tag'))
}
renderSwitch(param) {
  switch(param) {
    case 'Apple':
      return <Apple/>;
    case 'GooglePlay':
      return <Google/>;
    case 'xbox':
      return <Xbox/>;
    case 'ps':
      return <Ps/>;
    case 'mint':
      return <Mint/>;

    default:
      return null;
  }
}


submitorder =(e)=>{
  e.preventDefault()
  this.setState({btn : false})
  const formdata = new FormData()
  if(!this.state.amount){
    cogoToast.info('مقدار محصول را انتخاب کنید')
    return
  }
  if(parseInt(this.state.amount) > parseInt(this.state.balance)){
    cogoToast.info('موجودی کافی نیست')
    return
  }
  formdata.append('name' , this.state.slug)
  formdata.append('amount',this.state.amount)
  formdata.append('value',this.state.value)
  axios.post('/api/users/submit_new_order', formdata).then(response=>{
    if(response.data.status === 'error-fatal'){
      this.props.history.push('/')
    }
    if(response.data.status === 'success'){

      this.props.history.push('/panel/orderdetail/'+ response.data.orderid)
    }
    if(response.data.status === 'error'){

      cogoToast.info(response.data.message)
    }
  })
}
  render(){
    let param = this.state.currentp
    return(
      <>

        <div>
        <Row xs="1" sm="2" md="2" lg='2' xl='4' className='text-dark'>
           <Col>
            <div onClick={this.modalopen} data-pn='apple' className='product-card'>
            <Card  body className="text-center bg-transparant  m-2 radius itunes shadow">

               <CardTitle className="pb-5 font-weight-bold text-white" tag="h5">Itunes Gift Card</CardTitle>
               <CardText className='p-text-card m-auto'>گیفت کارت آیتونز</CardText>
             </Card>
            </div>
           </Col>
           <Col>
           <div onClick={this.modalopen} data-pn='google' className='product-card'>
           <Card body className="text-center bg-transparant  m-2 radius google shadow">

              <CardTitle className="pb-5 font-weight-bold text-white" tag="h5">Google Gift Card</CardTitle>
              <CardText className='p-text-card m-auto'>گیفت کارت گوگل</CardText>
            </Card>
           </div>
           </Col>
           <Col>
           <div onClick={this.modalopen} data-pn='xbox' className='product-card'>
           <Card body className="text-center bg-transparant  m-2 radius xbox shadow">

              <CardTitle className="pb-5 font-weight-bold text-white" tag="h5">Xbox Gift Card</CardTitle>
              <CardText className='p-text-card m-auto'>گیفت کارت Xbox</CardText>
            </Card>
           </div>
           </Col>
           <Col>
           <div onClick={this.modalopen} data-pn='ps' className='product-card'>
           <Card body className="text-center bg-transparant  m-2 radius ps shadow">

              <CardTitle className="pb-5 font-weight-bold text-white" tag="h5">PS Gift Card</CardTitle>
              <CardText className='p-text-card m-auto'>گیفت کارت PS</CardText>
            </Card>
           </div>
           </Col>
         </Row>
         <Row xs="1" sm="2" md="2" lg='2' xl='4' className='text-dark'>
            <Col>
            <div onClick={this.modalopen} data-pn='mint' className='product-card'>
            <Card body className="text-center bg-transparant  m-2 radius mint shadow">

               <CardTitle className="pb-5 font-weight-bold text-success" tag="h5">Mint Card</CardTitle>
               <CardText className='p-text-card m-auto'>مینت کارت</CardText>
             </Card>
            </div>
            </Col>
            <Col>
            <div onClick={this.modalopen} data-pn='netflix' className='product-card'>
            <Card body className="text-center bg-transparant  m-2 radius netflix shadow">

               <CardTitle className="pb-5 font-weight-bold text-white" tag="h5">Netflix Card</CardTitle>
               <CardText className='p-text-card m-auto'>نت فلیکس</CardText>
             </Card>
            </div>
            </Col>
            <Col>
            <div onClick={this.modalopen} data-pn='spotify' className='product-card'>
            <Card body className="text-center bg-transparant  m-2 radius spotify shadow">

               <CardTitle className="pb-5 font-weight-bold text-white" tag="h5">Spotify</CardTitle>
               <CardText className='p-text-card m-auto'>اسپاتیفای</CardText>
             </Card>
            </div>
            </Col>
            <Col>
            <div onClick={this.modalopen} data-pn='amazon' className='product-card'>
            <Card body className="text-center bg-transparant  m-2 radius amazon shadow">

               <CardTitle className="pb-5 font-weight-bold text-white" tag="h5">Amazon Gift Card</CardTitle>
               <CardText className='p-text-card m-auto'>کارت آمازون</CardText>
             </Card>
            </div>
          </Col>
          </Row>

          <Modal isOpen={this.state.modal} toggle={this.toggle} >
       <ModalHeader toggle={this.toggle}>{this.state.i.title}</ModalHeader>
       <ModalBody className='p-modal'>
       <div className='d-flex justify'>
         <span onClick={this.setvalue}  data-price='10' className='p-v-value'>10 $</span>
         <span onClick={this.setvalue}  data-price='25' className='p-v-value'>25 $</span>
         <span onClick={this.setvalue}  data-price='50' className='p-v-value'>50 $</span>
         <span onClick={this.setvalue}  data-price='100' className='p-v-value'>100 $</span>
       </div>
         <li className='m-3 rtl'><span className='mx-2'>{this.state.i.title}</span> {this.state.amount} دلاری</li>
       <div className='m3-5 rtl d-flex'>
       <li className='mx-3 justify'>مقدار تراکنش:</li>
       <NumberFormat value={this.state.value} displayType={'text'} thousandSeparator={true} />
     {this.state.value ?   <p className='mx-3'>تومان</p> : ''}
       </div>
       <hr/>
       <Button onClick={this.submitorder} color='info' block>ثبت سفارش</Button>
       </ModalBody>

     </Modal>
        </div>

      </>
    )
  }
}
export default Home
