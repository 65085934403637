import React from 'react'
import axios from 'axios'
import{Row,Col,Button,Form,FormGroup,Label,Input} from 'reactstrap'
import { BsApple,BsGoogle } from "react-icons/bs";
import {FaXbox,FaGooglePlay,FaPlaystation,FaHandsHelping} from "react-icons/fa"
import {SiNetflix,SiFsecure,SiFastly} from "react-icons/si"
import {Link} from 'react-router-dom'
import Pay from '../images/pngwing.com.png'
import Navber from './navbar'
import Slider from '../components/slider2'
import payment from '../images/payment.png'
import Footer from './footer'
import cogoToast from 'cogo-toast'

class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){

}
onSubmit = (e)=>{
  e.preventDefault()
  if(this.state.email === ''){
    cogoToast.info('ایمیل را وارد کنید')
    return
  }
  if(this.state.subject === ''){
    cogoToast.info('عنوان را وارد کنید')
    return
  }
  if(this.state.comp === ''){
    cogoToast.info('متن را وارد کنید')
    return
  }
  const formdata = new FormData()
  formdata.append('email',this.state.email)
  formdata.append('subject',this.state.subject)
  formdata.append('complaining',this.state.comp)
  axios.post('/api/complaining',formdata)
  cogoToast.success('ثبت شد')
}
onChange = (e)=>{
  this.setState({[e.target.name] : e.target.value})
}

  render(){
    return(
      <>

      <div className='hom'>
        <Navber/>
      <div  className='' >
      <h5 className='rtl'>تماس با ما</h5>
      <Row className='rtl' xl='3' lg='3' xs='1'>

        <Col className=' my-3 rtl' >
          <div className='d-flex'>
            <p className='font-weight-bold ml-2'>آدرس : </p>
            <p className='mx-2'>تبریز - ولیعصر -  فلکه همافر - گلباد 2 - پلاک 6</p>
          </div>
        </Col>
        <Col className='my-3 rtl' >
          <div className='d-flex'>
            <p className='font-weight-bold ml-2'>تماس : </p>
            <p className='ltr'>041 33 19 16 72</p>
          </div>
          <small className='rtl'>ساعت پاسخگویی : 10 الی 17</small>
        </Col>
        <Col className='my-3 rtl' >
          <div className='d-flex'>
            <p className='font-weight-bold ml-2'>ایمیل : </p>
            <p>info@rapitex.org</p>
          </div>
          <p>صاحب امتیاز : نادر عرفانی</p>
        </Col>

      </Row>

      <h5 className='rtl px-5' >فرم تماس</h5>
      <Row className='rtl' xl='3'>

        <Col xl={{size : 6 , offset:3}} className=' my-3 rtl mx-auto' >
        <Form>
        <FormGroup>
           <Label for="email">ایمیل</Label>
           <Input onChange={this.onChange} type="email" name="email" id="email" />
         </FormGroup>
         <FormGroup>
           <Label for="subject">عنوان </Label>
           <Input onChange={this.onChange} type="text" name="subject" id="subject" />
         </FormGroup>
         <FormGroup>
           <Label for="comp">توضیحات</Label>
           <Input onChange={this.onChange} type="text-area" name="comp" id="comp" />
         </FormGroup>
        <FormGroup>
         <Button onClick={this.onSubmit} type='submit' color='primary' className='px-5'>ثبت</Button>
        </FormGroup>
        </Form>
        </Col>

      </Row>

      </div>
      <div className='footer'>
      <Footer/>
      </div>
      </div>

      </>
    )
  }
}
export default Home
