import React from 'react'
import axios from 'axios'
import{Media,Row,Col,Button,Form,FormGroup,Label,Input,Card,CardTitle,CardText} from 'reactstrap'
import { BsApple,BsGoogle } from "react-icons/bs";
import {FaXbox,FaGooglePlay,FaPlaystation,FaHandsHelping} from "react-icons/fa"
import {SiNetflix,SiFsecure,SiFastly} from "react-icons/si"
import {Link} from 'react-router-dom'
import Pay from '../images/pngwing.com.png'
import Navber from './navbar'
import Slider from '../components/slider2'
import payment from '../images/paymentpng.png'
import Footer from './footer'
import Select from 'react-select';
import {Slide1,Slide2,Slide3} from './slides/slide01'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'
class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }

  }
componentDidMount(){

}

  render(){
    return(
      <>
          <div className='bg-dark'>
            <Navber/>
            <div className='homepage-section-1'>
            <Row>
              <Col>
              <Carousel infiniteLoop>
                <Slide1/>
                <Slide2/>
                <Slide3/>
                </Carousel>
              </Col>
            </Row>
            </div>

            <div className='dshow'>
            <Row className='bg-primary-gradient section-2 rtl text-light' >
              <Col >
                <p className="lead my-5">با خرید گیفت کارت آیتونز شرکت اپل میتوانید از خدمات فروشگاه اپل و اپل موزیک استفاده نمائید.</p>
                <hr className="my-2" />
                <p className="lead">این محصول در مبالغ 10 ,25 ,50 , 100 دلاری قابل خرید میباشد.</p>
              </Col>
              <Col  className='rtl  landing-img-itunes section-2-img' xs='12'>
              </Col>
            </Row>
            <Row className='section-2 rtl text-primary'>

              <Col className='rtl  landing-img-googleplay section-2-img' xs='12'>
              </Col>
              <Col >
                <p className="lead my-5">با  خرید گیفت کارت شرکت گوگل میتوانید از خدمات بی نظیر گوگل در فروشگاه و خرید بازی و اپلیکیشن و ... استفاده نمائید.</p>
                <hr className="my-2" />
                <p className="lead">این محصول در مبالغ 10 ,25 ,50 , 100 دلاری قابل خرید میباشد.</p>
              </Col>
            </Row>
            <Row className='bg-primary-gradient section-2 rtl text-light'>
              <Col >
                <p className="lead my-5">با گیفت کارت های Xboxو Play Station میتوانید بدون محدودیت بازی های شرکت  مایکروسافت و سونی را خریداری کنید</p>
                <p className="lead"></p>
              </Col>
              <Col className='rtl  landing-img-game section-2-img' xs='12'>
              </Col>
            </Row>

            </div>
            <div className='footer'>
            <Footer/>
            </div>
          </div>
      </>
    )
  }
}
export default Home
