import React from 'react'
import axios from 'axios'
import {
CardHeader,
CardBody,
CardText,
Card,Container,Col,Row} from 'reactstrap'
import cogoToast from 'cogo-toast'
import cookie from 'react-cookies'
class register extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               order: []

          }
     }
     onChange = (e) => {
          this.setState({
               [e.target.name]: e.target.value
          })
     }

     componentWillMount(){
       var id = this.props.match.params.id
       axios.get('/api/users/get_order/'+id ).then(response=>{
         this.setState({order : response.data.data})
       })
     }
     render() {
       const i = this.state.order
          return (
  <div dir="rtl" style={{'margin-top':'40px'}}>
  <Card>
  <CardHeader className='text-info radius' tag="p">جزئیات تراکنش شماره <span className='mx-2'>#{i.orderid}</span></CardHeader>
  <CardBody>
    <Row>
      <Col>
        <div>
          <p>
            <span>تاریخ :
            </span>
            <span className='mx-2'>{i.date}
            </span>
          </p>
          <p>
            <span>نوع سفارش :
            </span>
            <span className='mx-2'>{i.name}
            </span>
          </p>
          <p>
            <span>مقدار سفارش :
            </span>
            <span className='mx-2'>{i.amount}
            </span>
          </p>
          <p>
            <span>مبلغ کل :
            </span>
            <span className='mx-2'>{i.value}
            </span>
          </p>
          <p>
            <span>وضعیت سفارش:
            </span>
            <span className='mx-2'>{i.status}
            </span>
          </p>
        </div>
      </Col>
    </Row>
  </CardBody>


  </Card>
  </div>

     )

}}
export default register
