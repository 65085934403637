import React from 'react'
import axios from 'axios'
import{Row,Col,Button,Card,CardBody,CardTitle,CardHeader,CardText} from 'reactstrap'
import {Link} from 'react-router-dom'
import SimpleLineIcon from 'react-simple-line-icons'
import { RiEmotionUnhappyLine } from "react-icons/ri";
class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={
      data:[],
      transactions :[]
    }

  }
componentWillMount(){
 axios.get('/api/users/USerInfo').then(response=>{
   this.setState({transactions : response.data.transactions , balance : response.data.irr})
 })
}

  render(){
    return(
      <>

        <div>
        <Row xs="1" sm="2" md="2" lg='2' xl='4' className='text-dark'>
           <Col>
            <Link to="Profile">
            <Card body className="text-center m-2 radius shadow">
            <span className="icon">
            <SimpleLineIcon color='purple' name="user-following" size='large'  />
            </span>
               <CardTitle className="mt-3 " tag="h5">احراز هویت</CardTitle>
               <CardText className='d-none  d-sm-block'>ارسال مدارک</CardText>
             </Card>
            </Link>
           </Col>
           <Col><Link to="orders">
           <Card body className="text-center m-2 radius shadow">
           <span className="icon">
            <SimpleLineIcon color='purple' name="credit-card" size='large'  />
            </span>
              <CardTitle className="mt-3 " tag="h5">تراکنش ها</CardTitle>
              <CardText className='  d-none d-sm-block'>واریز - برداشت</CardText>
            </Card>
           </Link>
           </Col>
           <Col><Link to="Wallet">
           <Card body className="text-center m-2 radius shadow">
           <span className="icon">
            <SimpleLineIcon color='purple' name="wallet" size='large'  />
            </span>
              <CardTitle className="mt-3 " tag="h5">کیف پول</CardTitle>
              <CardText className=' d-none d-sm-block'><span>{this.state.balance}</span><span className='mr-2'>تومان</span></CardText>
            </Card>
           </Link>
           </Col>
           <Col><Link to="messages">
           <Card body className="text-center m-2 radius shadow">
           <span className="icon">
            <SimpleLineIcon name="bell" color='purple' size='large'  />
            </span>
              <CardTitle className="mt-3 " tag="h5">پیام ها</CardTitle>
              <CardText className=' d-none d-sm-block'>تاریخچه ایمیل </CardText>
            </Card>
           </Link></Col>
         </Row>
         <Card className="mt-5 mx-2 radius shadow">
         <CardHeader>تراکنش های اخیر
         </CardHeader>
           <CardBody>
             <CardTitle className="rtl p-auto" tag="h5"></CardTitle>
             {this.state.transactions.length > 0 ? <>
                {this.state.transactions.slice(0,5).map((i,index)=>
                  <p className='justify-content-space-between rtl d-flex'>
                    <span >{index +1})
                    </span>

                    <span className='mx-3 w100'>{i.date}
                    </span>
                    <span className='mx-3 w50'>{i.currency}
                    </span>
                    <span className='mx-3 w150'>{i.amount}
                    </span>

                    <span className='mr-auto w50'>{i.status}
                    </span>
                    <span className='mr-auto'>{i.type}
                    </span>
                  </p>
                )}
               </> :
               <div className="pt-5 text-center">
             <RiEmotionUnhappyLine size='6em' color='lightgray'/>
             <h3>تراکنشی ندارید !</h3>
             </div>
             }
           </CardBody>
         </Card>

         <Card body className="mt-5 mx-2 radius shadow">
           <div>
             <CardTitle className="rtl p-auto" tag="h5">پیام های اخیر</CardTitle>
            <small>پیامی ندارید !</small>
           </div>
         </Card>

          <Row xl='4' lg='4' md='2' sm='2' xs='1'>
            <Col className='border'>
            </Col>
            <Col className='border'>
            </Col>
            <Col className='border'>
            </Col>
            <Col className='border'>
            </Col>
          </Row>
        </div>

      </>
    )
  }
}
export default Home
